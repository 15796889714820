/*styles.css*/
@import "../../node_modules/normalize.css/normalize.css";

$cWhite: #fff;
$cBlack: #000;
$cBlue: #1E1954;
$cGreen: #5CA246;

$cBase: $cBlue;
$cPrimary: $cBlue;

$bp-xs: 576px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-lg: 1200px;

$bdrs: 3px;

$z0: 0;
$z1: 1;
$z2: 10;
$z3: 100;
$z4: 1000;

$font: 'Source Sans Pro', sans-serif;
$font1: 'Mukta', sans-serif;

* {
  box-sizing: border-box;
}

*:after,
*:before {
  box-sizing: inherit;
}

body {
  background-color: $cWhite;
  font-family: $font;
  color: $cBase;
  -webkit-font-smoothing: antialiased;
}


.wrapper {
  @media (min-width: $bp-lg) {
    border-width: 18px;
    border-style: solid;
    border-image: url(./images/border.gif) 18 / 18px round;
  }
}

.is-scrolling .wrapper {
  padding-top: 56px;
  @media (min-width: $bp-md) {
    padding-top: 156px;
  }
}

/* .dummy {
  position: relative;
  top: -56px;
  height: 1px;
  width: 100%;
} */

strong {
  font-weight: 700;
}

img {
  vertical-align: top;
}

a {
  text-decoration: none;
  color: $cPrimary;
}

a:hover {
  text-decoration: underline;
  color: $cPrimary;
}

h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: left;
  display: inline-block;
  line-height: 42px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  @media (min-width: $bp-md) {
    font-size: 38px;
    line-height: 60px;
  }
  @media (min-width: $bp-lg) {
    text-align: left;
  }
}

h1 span {
  font-weight: 300;
  font-size: 29px;
  line-height: 42px;
  display: block;
}

h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 36px 0;
  padding: 0;
  line-height: 42px;
  color: $cGreen;
  font-family: $font1;
  text-align: center;
  @media (min-width: $bp-md) {
    font-size: 54px;
    line-height: 66px;
  }
}

h3 {
  margin: 0;
  padding: 0;
  font-family: $font1;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  @media (min-width: $bp-md) {
    font-size: 33px;
    line-height: 42px;
  }
}

h3.alt {
  padding-left: 48px;
  position: relative;
}

h3.alt::before {
  content: attr(data-number);
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: $cGreen;
  border-radius: 50%;
  text-align: center;
  color: $cWhite;
  font-size: 24px;
  font-family: $font1;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
}

p {
  line-height: 30px;
  margin: 24px 0 0 0;
  font-size: 16px;
}

ul {
  font-size: 16px;
  line-height: 24px;
  margin: 0 36px 48px 0;
  padding: 0;
  @media (min-width: $bp-md) {
    font-size: 18px;
    line-height: 30px;
  }
}

ul ul {
  margin: 0;
}

ul li {
  margin: 0;
  padding-left: 48px;
  position: relative;
  list-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

ul ul li {
  list-style: decimal;
  list-style-position: outside;
  padding-left: 12px;
  margin-left: 12px;
}

ul li::before {
  content: "-";
  position: absolute;
  left: 16px;
  top: 10px;
  font-weight: bold;
  font-size: 18px;
}

ul ul li::before {
  display: none;
}

.well {
  background: rgba(92,162,70,0.12);
  padding: 24px;
  font-size: 18px;
  line-height: 30px;
  font-style: italic;
  margin-bottom: 36px;
  margin-top: 36px;
  @media (min-width: $bp-md) {
    font-size: 24px;
    padding: 36px;
    line-height: 36px;
  }
}

@import "_footer.css";
@import "_header.css";
@import "_img-block.css";
@import "_layout.css";
@import "_person.css";

@import "_utils.css";

@import "_shame.css";
