.person {
  display: flex;
  align-items: center;
  margin: 24px 0;
}

.person__number {
  font-weight: bold;
  font-size: 16px;
  @media (min-width: $bp-md) {
    font-size: 18px;
  }
}

.person__image {
  margin: 0 18px 0 10px;
  width: 90px;
  @media (min-width: $bp-md) {
    width: 180px;
  }
}

.person__info {
  font-size: 14px;
  line-height: 24px;
  flex: 1;
  @media (min-width: $bp-md) {
    font-size: 18px;
    line-height: 30px;
  }
}

.person__info strong {
  text-transform: uppercase;
}