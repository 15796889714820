.fluid {
  max-width: 100%;
}

.over {
  display: block;
  text-decoration: none;
}

.is-hidden {
  display: none;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-large {
  font-size: 18px;
  line-height: 30px;
}
.text-small {
  font-size: 13px;
  color: $cGray;
}

.color--white {
  color: $cWhite;
}
.color--gray {
  color: $cGray2;
}

@media (max-width: $bp-xs) {
  .is-hidden\@xs {
    display: none !important;
  }
  .text-center\@xs {
    text-align: center;
  }
}

@media (min-width: $bp-sm) and (max-width: $bp-md) {
  .is-hidden\@sm {
    display: none !important;
  }
  .text-center\@sm {
    text-align: center;
  }
}

@media (min-width: $bp-md) and (max-width: $bp-lg) {
  .is-hidden\@md {
    display: none !important;
  }
  .text-center\@md {
    text-align: center;
  }
}

@media (min-width: $bp-lg) {
  .is-hidden\@lg {
    display: none !important;
  }
  .text-center\@lg {
    text-align: center;
  }
}

@media (min-width: $bp-sm) {
  .is-hidden\@sm-up {
    display: none !important;
  }
}

@media (min-width: $bp-md) {
  .is-hidden\@md-up {
    display: none !important;
  }
}
@media (max-width: $bp-md) {
  .is-hidden\@md-down {
    display: none !important;
  }
}

@media (max-width: $bp-md) {
  .text-center\@sm-down {
    text-align: center;
  }
}

.mt0 {
  margin-top: 0px !important;
}
.mt0-5 {
  margin-top: 6px !important;
}
.mt1 {
  margin-top: 12px !important;
}
.mt1-5 {
  margin-top: 18px !important;
}
.mt2 {
  margin-top: 24px !important;
}
.mt3 {
  margin-top: 36px !important;
}
.mt4 {
  margin-top: 48px !important;
}
.mt5 {
  margin-top: 60px !important;
}
.mt6 {
  margin-top: 72px !important;
}
.mt7 {
  margin-top: 84px !important;
}
.mt8 {
  margin-top: 96px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}
.mb0-5 {
  margin-bottom: 6px !important;
}
.mb1 {
  margin-bottom: 12px !important;
}
.mb1-5 {
  margin-bottom: 18px !important;
}
.mb2 {
  margin-bottom: 24px !important;
}
.mb3 {
  margin-bottom: 36px !important;
}
.mb4 {
  margin-bottom: 48px !important;
}
.mb5 {
  margin-bottom: 60px !important;
}
.mb6 {
  margin-bottom: 72px !important;
}
.mb7 {
  margin-bottom: 84px !important;
}
.mb8 {
  margin-bottom: 96px !important;
}

@media (min-width: $bp-sm) {
  .mt0\@sm {
    margin-top: 0px !important;
  }
  .mt0-5\@sm {
    margin-top: 6px !important;
  }
  .mt1\@sm {
    margin-top: 12px !important;
  }
  .mt1-5\@sm {
    margin-top: 18px !important;
  }
  .mt2\@sm {
    margin-top: 24px !important;
  }
  .mt3\@sm {
    margin-top: 36px !important;
  }
  .mt4\@sm {
    margin-top: 48px !important;
  }
  .mt5\@sm {
    margin-top: 60px !important;
  }
  .mt6\@sm {
    margin-top: 72px !important;
  }
  .mt7\@sm {
    margin-top: 84px !important;
  }
  .mt8\@sm {
    margin-top: 96px !important;
  }

  .mb0\@sm {
    margin-bottom: 0px !important;
  }
  .mb0-5\@sm {
    margin-bottom: 6px !important;
  }
  .mb1\@sm {
    margin-bottom: 12px !important;
  }
  .mb1-5\@sm {
    margin-bottom: 18px !important;
  }
  .mb2\@sm {
    margin-bottom: 24px !important;
  }
  .mb3\@sm {
    margin-bottom: 36px !important;
  }
  .mb4\@sm {
    margin-bottom: 48px !important;
  }
  .mb5\@sm {
    margin-bottom: 60px !important;
  }
  .mb6\@sm {
    margin-bottom: 72px !important;
  }
  .mb7\@sm {
    margin-bottom: 84px !important;
  }
  .mt8\@sm {
    margin-bottom: 96px !important;
  }
}

@media (min-width: $bp-md) {
  .mt0\@md {
    margin-top: 0px !important;
  }
  .mt0-5\@md {
    margin-top: 6px !important;
  }
  .mt1\@md {
    margin-top: 12px !important;
  }
  .mt1-5\@md {
    margin-top: 18px !important;
  }
  .mt2\@md {
    margin-top: 24px !important;
  }
  .mt3\@md {
    margin-top: 36px !important;
  }
  .mt4\@md {
    margin-top: 48px !important;
  }
  .mt5\@md {
    margin-top: 60px !important;
  }
  .mt6\@md {
    margin-top: 72px !important;
  }
  .mt7\@md {
    margin-top: 84px !important;
  }
  .mt8\@md {
    margin-top: 96px !important;
  }

  .mb0\@md {
    margin-bottom: 0px !important;
  }
  .mb0-5\@md {
    margin-bottom: 6px !important;
  }
  .mb1\@md {
    margin-bottom: 12px !important;
  }
  .mb1-5\@md {
    margin-bottom: 18px !important;
  }
  .mb2\@md {
    margin-bottom: 24px !important;
  }
  .mb3\@md {
    margin-bottom: 36px !important;
  }
  .mb4\@md {
    margin-bottom: 48px !important;
  }
  .mb5\@md {
    margin-bottom: 60px !important;
  }
  .mb6\@md {
    margin-bottom: 72px !important;
  }
  .mb7\@md {
    margin-bottom: 84px !important;
  }
  .mb8\@md {
    margin-bottom: 96px !important;
  }
}

@media (min-width: $bp-lg) {
  .mt0\@lg {
    margin-top: 0px !important;
  }
  .mt0-5\@lg {
    margin-top: 6px !important;
  }
  .mt1\@lg {
    margin-top: 12px !important;
  }
  .mt1-5\@lg {
    margin-top: 18px !important;
  }
  .mt2\@lg {
    margin-top: 24px !important;
  }
  .mt3\@lg {
    margin-top: 36px !important;
  }
  .mt4\@lg {
    margin-top: 48px !important;
  }
  .mt5\@lg {
    margin-top: 60px !important;
  }
  .mt6\@lg {
    margin-top: 72px !important;
  }
  .mt7\@lg {
    margin-top: 84px !important;
  }
  .mt8\@lg {
    margin-top: 96px !important;
  }

  .mb0\@lg {
    margin-bottom: 0px !important;
  }
  .mb0-5\@lg {
    margin-bottom: 6px !important;
  }
  .mb1\@lg {
    margin-bottom: 12px !important;
  }
  .mb1-5\@lg {
    margin-bottom: 18px !important;
  }
  .mb2\@lg {
    margin-bottom: 24px !important;
  }
  .mb3\@lg {
    margin-bottom: 36px !important;
  }
  .mb4\@lg {
    margin-bottom: 48px !important;
  }
  .mb5\@lg {
    margin-bottom: 60px !important;
  }
  .mb6\@lg {
    margin-bottom: 72px !important;
  }
  .mb7\@lg {
    margin-bottom: 84px !important;
  }
  .mb8\@lg {
    margin-bottom: 96px !important;
  }
}
