.footer {
  background-color: $cBlue;
  text-align: center;
  padding-top: 36px;
  padding-bottom: 112px;
  color: $cWhite;
}

.footer h3 {
  font-size: 24px;
  line-height: 36px;
  font-weight: normal;
  color: $cWhite;
  @media (min-width: $bp-md) {
    font-size: 33px;
    line-height: 48px;
  }
}

.footer p {
  font-size: 18px;
  line-height: 24px;
  @media (min-width: $bp-md) {
    font-size: 24px;
    line-height: 36px;
  }
}

.footer__logo {
  margin-top: 72px;
}