.header {
  padding: 10px 0;
  position: sticky;
  top: 0;
  background-color: $cWhite;
  transition: all 300ms ease;
  @media (min-width: $bp-md) {
    padding: 30px 0;
  }
}

.header .container {
  display: flex;
  transition: all 300ms ease;
  height: 36px;
  align-items: center;
  @media (min-width: $bp-md) {
    height: 96px;
    align-items: flex-start;
  }
}

.header__nav {
  margin-left: auto;
}

.header__nav a {
  font-weight: bold;
  font-size: 18px;
  margin-right: 15px;
  @media (min-width: $bp-md) {
    margin-left: 30px;
  }
}

.header__flow {
  position: absolute;
  left: 50%;
  top: 78px;
  transform: translateX(-50%);
  z-index: 2;
  transition: all 300ms ease;
  opacity: 1;
  display: none;
  @media (min-width: $bp-md) {
    display: block;
  }
}

.header__logo {
  width: 50px;
  height: auto;
  vertical-align: middle;
  transition: all 300ms ease;
  @media (min-width: $bp-md) {
    width: 223px;
  }
}

.is-scrolling .header__flow {
  opacity: 0;
}

.is-scrolling .header {
  padding: 10px 0;
  z-index: 1;
}

.is-scrolling .header .container {
  height: 36px;
  align-items: center;
}

.is-scrolling .header__logo {
  width: 50px;
  @media (min-width: $bp-md) {
    width: 80px;
  }
}