.container {
  max-width: 1146px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  @media (min-width: $bp-sm) {
  display: flex;
  }
}

.col-50 {
  @media (min-width: $bp-sm) {
    width: 50%;
  }
}